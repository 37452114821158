import { Container } from '@seuk/design-system/atoms';

import { Main, UnderConstruction } from '../../components';

interface PageProps {
  title: string;
  description: string;
}

function ErrorPage({ description, title }: PageProps) {
  return (
    <>
      <Main>
        <Container>
          <UnderConstruction title={title} description={description} />
        </Container>
      </Main>
    </>
  );
}

export function makePage(title: string, description: string): React.FC<PageProps> {
  return function CustomErrorPage() {
    return <ErrorPage title={title} description={description} />;
  };
}
